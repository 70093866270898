import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    background: (t) => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.beta} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`,
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, `full`, `2/3`],
  },
}

// export default () => (
//   <>
//     <Divider space={3} />
//     <Heading variant='h1' sx={styles.heading}>
//       Unlock your programming instincts{/* <br />
//       the best programmers */}
//     </Heading>
//     <Divider space={3} />
//     <Heading variant='h3' sx={styles.running}></Heading>
//   </>
// )

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1' sx={styles.heading}>
      What makes a great programmer?
    </Heading>
    <Divider space={3} />
    <Heading variant='h3' sx={styles.running}>
      Being able to build complex machine learning algorithms?
    </Heading>
    <Heading variant='h3' sx={styles.running}>
      Bewitching websites that can handle millions of users ?
    </Heading>
    <Heading variant='h3' sx={styles.running}>
      Or the next big open source project ?
    </Heading>

    {/* <br />
      Or the next big operating system ? */}
    <Heading variant='h3' sx={styles.running}>
      That, my friend is for you to decide. We are here to provide the tools and
      the knowledge to help you in your path.
    </Heading>
  </>
)
